.top-divider {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.top-divider svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 150px;
}

.top-divider .shape-fill {
    fill: #000000;
}